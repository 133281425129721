* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.discordButton {
  background-color: #5865f2;
  color: #fff;
}

.discordButton:hover {
  background-color: #525ee0;
}
