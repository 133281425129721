.input-flash-game {
  background-color: var(--mantine-color-dark-4);
  /* color: var(--mantine-color-dark-9); */
  font-weight: bold;
  padding: 40px;
  font-size: 26px;
  &::placeholder {
    color: var(--mantine-color-dark-1);
  }
}

.input-flash-game-success {
  border: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-green-4);
  border-radius: var(--_input-radius);
}
