.icon {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mantine-spacing-lg);
  color: var(--mantine-color-blue-6);
}

.stat {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--mantine-color-white);
}

.label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: var(--mantine-font-size-xs);
  font-family: Greycliff CF, var(--mantine-font-family);
  color: var(--mantine-color-gray-6);
  line-height: 1.2;
}

.value {
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
}

.count {
  color: var(--mantine-color-gray-6);
}
